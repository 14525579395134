const travelJSONData = [
  {
    id: 0,
    title: "Dublin",
    country: {name:"Ireland", code:"ie"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Dublin/@53.3242066,-6.4105096,11z/data=!3m1!4b1!4m6!3m5!1s0x48670e80ea27ac2f:0xa00c7a9973171a0!8m2!3d53.3498053!4d-6.2603097!16zL20vMDJjZnQ?entry=ttu",
    startDate: "",
    endDate: "",
    description: "Home City",
    imageUrl: "dublin.jpeg",
    tags: ["Home Country"],
  },
  {
    id: 1,
    title: "Rome (1st time)",
    country: {name:"Italy", code:"it"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Rome,+Metropolitan+City+of+Rome,+Italy/@41.9100711,12.5359979,11z/data=!3m1!4b1!4m5!3m4!1s0x132f6196f9928ebb:0xb90f770693656e38!8m2!3d41.9027835!4d12.4963655",
    startDate: "8 July 2016",
    endDate: "13 July 2016",
    description: "Ice Cream. The Forum. 40 degree heat.",
    imageUrl: "rome2.jpeg",
    tags: ["Amazing Food"],
  },
  {
    id: 2,
    title: "Florence",
    country: {name:"Italy", code:"it"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Metropolitan+City+of+Florence,+Italy/@43.8453733,10.670229,9z/data=!3m1!4b1!4m5!3m4!1s0x132af8a078bd7663:0x3082c90e3e48060!8m2!3d43.7679178!4d11.2523792",
    startDate: "14 July 2016",
    endDate: "17 July 2016",
    description: "Three Davids. Sunset at Piazzale Michelangelo.",
    imageUrl: "florence.jpeg",
    tags: ["Amazing Food"],
  },
  {
    id: 3,
    title: "Pisa",
    country: {name:"Italy", code:"it"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Pisa,+Province+of+Pisa,+Italy/@43.7067293,10.3253381,12z/data=!3m1!4b1!4m5!3m4!1s0x12d5919af0f6598f:0xaab80fb5a78478c8!8m2!3d43.7228386!4d10.4016888",
    startDate: "17 July 2016",
    endDate: "17 July 2016",
    description: "Tourist Crazy. Day trip. Small City.",
    imageUrl: "pisa.jpeg",
  },
  {
    id: 4,
    title: "Venice",
    country: {name:"Italy", code:"it"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Venice,+Metropolitan+City+of+Venice,+Italy/@45.4042007,12.1071474,10z/data=!3m1!4b1!4m5!3m4!1s0x477eb1daf1d63d89:0x7ba3c6f0bd92102f!8m2!3d45.4408474!4d12.3155151",
    startDate: "18 July 2016",
    endDate: "19 July 2016",
    description: "Night train out. Gondola ride. Sunsets.",
    imageUrl: "venice.jpeg",
  },
  {
    id: 5,
    title: "Vienna",
    country: {name:"Austria", code:"at"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Vienna,+Austria/@48.2203445,16.0998764,10z/data=!3m1!4b1!4m5!3m4!1s0x476d079e5136ca9f:0xfdc2e58a51a25b46!8m2!3d48.2081743!4d16.3738189",
    startDate: "19 July 2016",
    endDate: "23 July 2016",
    description: "Open air opera. Museums. Castles.",
    imageUrl: "vienna.jpeg",
  },
  {
    id: 6,
    title: "Budapest (1st time)",
    country: {name:"Hungary", code:"hu"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Budapest,+Hungary/@47.4808722,18.850119,10z/data=!3m1!4b1!4m5!3m4!1s0x4741c334d1d4cfc9:0x400c4290c1e1160!8m2!3d47.497912!4d19.040235",
    startDate: "24 July 2016",
    endDate: "29 July 2016",
    description: "Musical Fountain. Bath Party. Ruin Bars.",
    imageUrl: "budapest2.jpeg",
  },
  {
    id: 7,
    title: "Edinburgh",
    country: {name:"Scotland", code:"gb-sct"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Edinburgh,+UK/data=!4m2!3m1!1s0x4887b800a5982623:0x64f2147b7ce71727?sa=X&ved=2ahUKEwiRyfifmp76AhWZd8AKHYaGAloQ8gF6BAgIEAE",
    startDate: "20 January 2017",
    endDate: "23 January 2017",
    description: "Castles. Bagpipes. Haggis. The Elephant House. Tom Riddle's Grave.",
    imageUrl: "edinburgh.jpeg",
  },
  {
    id: 8,
    title: "Munich",
    country: {name:"Germany", code:"de"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Munich,+Germany/@48.1550267,11.2609595,10z/data=!3m1!4b1!4m5!3m4!1s0x479e75f9a38c5fd9:0x10cb84a7db1987d!8m2!3d48.1351253!4d11.5819805",
    startDate: "22 July 2017",
    endDate: "27 July 2017",
    description: "Sleeping Beauty Castle. Dachau. Bowling.",
    imageUrl: "munich.jpeg",
  },
  {
    id: 9,
    title: "Prague",
    country: {name:"Czech Republic", code:"cz"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Prague,+Czechia/@53.853109,-9.3062977,17z/data=!4m5!3m4!1s0x470b939c0970798b:0x400af0f66164090!8m2!3d50.0755381!4d14.4378005?hl=en-GB",
    startDate: "27 July 2017",
    endDate: "31 July 2017",
    description: "Astronomical Clock.",
    imageUrl: "prague.jpeg",
  },
  {
    id: 10,
    title: "Barcelona",
    country: {name:"Spain", code:"es"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Barcelona,+Spain/@53.853109,-9.3062977,17z/data=!4m5!3m4!1s0x12a49816718e30e5:0x44b0fb3d4f47660a!8m2!3d41.3873974!4d2.168568?hl=en-GB",
    startDate: "16 February 2018",
    endDate: "18 February 2018",
    description: "Gaudi. Park Güell. Tapas.",
    imageUrl: "barcelona.jpeg",
  },
  {
    id: 11,
    title: "London",
    country: {name:"England", code:"gb-eng"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/London,+UK/@51.5283063,-0.3824667,10z/data=!3m1!4b1!4m5!3m4!1s0x47d8a00baf21de75:0x52963a5addd52a99!8m2!3d51.5072178!4d-0.1275862",
    startDate: "12 April 2018",
    endDate: "15 April 2018",
    description: "Harry Potter Studios. V&A Museum. Portait Museum. Bounce Ping Pong.",
    imageUrl: "london.jpeg",
  },
  {
    id: 12,
    title: "Malta",
    country: {name:"Malta", code:"mt"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Malta/@35.8775608,14.1087046,9.64z/data=!4m5!3m4!1s0x130e45281d8647c5:0xf582d86136be4239!8m2!3d35.937496!4d14.375416",
    startDate: "17 September 2018",
    endDate: "28 September 2018",
    description: "Walled Cities. Boat trip to Gozo. Game of Thrones. Cocktails.",
    imageUrl: "malta.jpeg",
  },
  {
    id: 13,
    title: "Amsterdam",
    country: {name:"Netherlands", code:"nl"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Amsterdam,+Netherlands/@52.3545828,4.7638773,11z/data=!3m1!4b1!4m5!3m4!1s0x47c63fb5949a7755:0x6600fd4cb7c0af8d!8m2!3d52.3675734!4d4.9041389",
    startDate: "9 May 2019",
    endDate: "13 May 2019",
    description: "Banksy. Van Gogh. Keukenhof Flowers. Clogs. Windmills.",
    imageUrl: "amsterdam.png",
  },
  {
    id: 14,
    title: "Berlin",
    country: {name:"Germany", code:"de"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Berlin,+Germany/@52.5065133,13.1445513,10z/data=!3m1!4b1!4m5!3m4!1s0x47a84e373f035901:0x42120465b5e3b70!8m2!3d52.5200066!4d13.404954",
    startDate: "19 August 2019",
    endDate: "25 August 2019",
    description: "Open air Berlin Philharmonic concert. Archaeopteryx. Disco in a phone box.",
    imageUrl: "berlin.jpg",
    tags: ["Dinosaurs"]
  },
  {
    id: 15,
    title: "Longford Forest",
    country: {name:"Ireland", code:"ie"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Longford/@53.7265587,-7.8117824,14z/data=!3m1!4b1!4m5!3m4!1s0x485dd39bb9c2c387:0xa00c7a997317370!8m2!3d53.7276296!4d-7.7932573",
    startDate: "16 December 2019",
    endDate: "20 December 2019",
    description: "Center Parcs: Waterpark. Family fun. Fencing. Badminton. Owls. Cake Decorating",
    imageUrl: "longford.jpeg",
    tags: ["Home Country"],
  },
  {
    id: 16,
    title: "Paphos",
    country: {name:"Cyprus", code:"cy"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Paphos,+Cyprus/@34.7726773,32.3979051,13z/data=!3m1!4b1!4m5!3m4!1s0x14e70663f42de2c9:0x6c05fdff50f4b5e7!8m2!3d34.7753949!4d32.4217786",
    startDate: "30 October 2020",
    endDate: "7 November 2020",
    description: "Coral Bay. Beach Bar. Lara Beach.",
    imageUrl: "paphos.jpg",
  },
  {
    id: 17,
    title: "Ayia Napa",
    country: {name:"Cyprus", code:"cy"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Ayia+Napa,+Cyprus/@34.9855357,33.9397259,12z/data=!3m1!4b1!4m5!3m4!1s0x14dfc568a7918259:0x96582c199eafaa40!8m2!3d34.9887366!4d34.0002782",
    startDate: "7 November 2020",
    endDate: "11 November 2020",
    description: "Cactus Park. Best Beach. Yellow Submarine.",
    imageUrl: "aiyanapa.jpeg",
  },
  {
    id: 18,
    title: "Larnaca",
    country: {name:"Cyprus", code:"cy"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Larnaca,+Cyprus/@34.9014941,33.5500226,12z/data=!3m1!4b1!4m5!3m4!1s0x14e082a16c40cb13:0x4fcbf0221371d0a5!8m2!3d34.9182222!4d33.6200625",
    startDate: "11 November 2020",
    endDate: "14 November 2020",
    description: "Wine tasting. Restaurants. Birthday dinner. Salt Flats.",
    imageUrl: "larnaca.jpg",
  },
  {
    id: 19,
    title: "Belfast",
    country: {name:"Northern Ireland", code:"gb-nir"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Belfast,+UK/@54.5949592,-5.9966719,12z/data=!3m1!4b1!4m5!3m4!1s0x4860fffdd7d08a3b:0x2e57162cefc7c531!8m2!3d54.597285!4d-5.93012",
    startDate: "21 May 2021",
    endDate: "30 May 2021",
    description: "Titanic. Cycling around the city. Belfast Museum. James restaurant.",
    imageUrl: "belfast.jpeg",
  },
  {
    id: 20,
    title: "Athens",
    country: {name:"Greece", code:"gr"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Athens,+Greece/@37.9908164,23.6682993,12z/data=!3m1!4b1!4m5!3m4!1s0x14a1bd1f067043f1:0x2736354576668ddd!8m2!3d37.9838096!4d23.7275388",
    startDate: "14 August 2021",
    endDate: "18 August 2021",
    description: "Rooftop bars. Acropolis. Cable car rides. Greco's Project.",
    imageUrl: "athens.jpg",
    tags: ["Remote Working", "Amazing Food"],
  },
  {
    id: 21,
    title: "Nea Makri",
    country: {name:"Greece", code:"gr"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Nea+Makri+190+05,+Greece/@38.0694376,23.9424477,13z/data=!3m1!4b1!4m5!3m4!1s0x14a182c1ac72aab5:0xb38f8f26c5b7e893!8m2!3d38.0878845!4d23.9761139",
    startDate: "18 August 2021",
    endDate: "25 August 2021",
    description: "Beach. Boat trips. Praying Mantis. Water sports.",
    imageUrl: "nea-makri.jpg",
    tags: ["Remote Working"],
  },
  {
    id: 22,
    title: "Mykonos",
    country: {name:"Greece", code:"gr"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Mikonos+846+00,+Greece/@37.444457,25.3180739,14z/data=!3m1!4b1!4m5!3m4!1s0x14a2bf06bf0fdd37:0xfaa85debe77b7a44!8m2!3d37.4467185!4d25.3288623",
    startDate: "25 August 2021",
    endDate: "28 August 2021",
    description: "Boat Parties. Watermelon mojitos. Tropicana beach club.",
    imageUrl: "mykonos.jpg",
  },
  {
    id: 23,
    title: "Paros",
    country: {name:"Greece", code:"gr"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Paros+844+00,+Greece/@37.0854854,25.1371132,14z/data=!3m1!4b1!4m5!3m4!1s0x1498710f28b4bfa7:0xf59bedca90e4a6ae!8m2!3d37.0856432!4d25.1488318",
    startDate: "28 August 2021",
    endDate: "1 September 2021",
    description: "Lots of cats. Dubliner. Captain Ben",
    imageUrl: "paros.jpeg",
  },
  {
    id: 24,
    title: "Santorini",
    country: {name:"Greece", code:"gr"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Thera+847+00,+Greece/@36.4203259,25.4234157,15z/data=!3m1!4b1!4m5!3m4!1s0x1499cdce05e3bce9:0x9f4c192bbefa1db!8m2!3d36.4166485!4d25.432447",
    startDate: "1 September 2021",
    endDate: "4 September 2021",
    description: "Jacuzzi. Red Beach. Black Beach. Sunset in Oia.",
    imageUrl: "santorini.jpg",
  },
  {
    id: 25,
    title: "Crete",
    country: {name:"Greece", code:"gr"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Crete,+Greece/@35.2431181,23.790838,8z/data=!3m1!4b1!4m5!3m4!1s0x149afe2f827d98a1:0x100bd2ce2b9c630!8m2!3d35.240117!4d24.8092691",
    startDate: "4 September 2021",
    endDate: "16 September 2021",
    description: "Boot of beer. Balos Beech. Elafonisi. Moussaka",
    imageUrl: "crete.jpeg",
    tags: ["Amazing Food"],
  },
  {
    id: 26,
    title: "Rome (2nd time)",
    country: {name:"Italy", code:"it"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Metropolitan+City+of+Rome,+Italy/@41.8504438,11.9544673,9z/data=!3m1!4b1!4m5!3m4!1s0x13258a111bd74ac3:0x3094f9ab2388100!8m2!3d41.9027008!4d12.4962352",
    startDate: "16 September 2021",
    endDate: "24 September 2021",
    description: "You are in Rome now, you must be Rome-antic.",
    imageUrl: "rome.jpeg",
    tags: ["Remote Working"],
  },
  {
    id: 27,
    title: "Vatican City",
    country: {name:"Vatican City", code:"va"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/00120+Vatican+City/@53.853109,-9.3062977,17z/data=!4m5!3m4!1s0x1325890a57d42d3d:0x94f9ab23a7eb0!8m2!3d41.902916!4d12.453389?hl=en-GB",
    startDate: "19 September 2021",
    endDate: "19 September 2021",
    description: "Pope Francis. Postcard from the smallest country. St Peter's Cathedral.",
    imageUrl: "vatican.jpg",
  },
  {
    id: 28,
    title: "Sorrento (1st time)",
    country: {name:"Italy", code:"it"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/80067+Sorrento,+Metropolitan+City+of+Naples,+Italy/@40.6237862,14.3576817,14z/data=!3m1!4b1!4m5!3m4!1s0x133b99536dbf5b61:0x2644eb57abc0ce40!8m2!3d40.6262925!4d14.3757985",
    startDate: "24 September 2021",
    endDate: "27 September 2021",
    description: "Lemoncello spritz. Capri. Pompeii",
    imageUrl: "sorrento.jpeg",
    tags: ["Remote Working", "Amazing Food"],
  },
  {
    id: 29,
    title: "Meta",
    country: {name:"Italy", code:"it"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/80062+Meta,+Campania,+Metropolitan+City+of+Naples,+Italy/@40.6401907,14.408546,15z/data=!3m1!4b1!4m5!3m4!1s0x133b9908fa0c0919:0xb396785a92762205!8m2!3d40.6428867!4d14.4173398",
    startDate: "27 September 2021",
    endDate: "1 October 2021",
    description: "Beach. Mountain Scenery. Natural Azzuro",
    imageUrl: "meta.jpeg",
    tags: ["Remote Working"],
  },
  {
    id: 30,
    title: "Palermo",
    country: {name:"Italy", code:"it"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Palermo,+PA,+Italy/@38.1405228,13.2872484,12z/data=!3m1!4b1!4m5!3m4!1s0x1319e8c9814ed099:0xa0b042c233bd880!8m2!3d38.11569!4d13.3614868",
    startDate: "1 October 2021",
    endDate: "8 October 2021",
    description: "Food markets. Swordfish. Mondello Beach",
    imageUrl: "palermo.jpeg",
    tags: ["Remote Working", "Amazing Food"],
  },
  {
    id: 31,
    title: "Taormina",
    country: {name:"Italy", code:"it"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/98039+Taormina,+Metropolitan+City+of+Messina,+Italy/@37.8548943,15.2784163,15z/data=!3m1!4b1!4m5!3m4!1s0x131411a191a0d9b5:0x74a19a34d616e949!8m2!3d37.8516366!4d15.2853127",
    startDate: "9 October 2021",
    endDate: "11 October 2021",
    description: "Greek Theatre. Cablecar. Mt Etna. Alacntra Gorges.",
    imageUrl: "taormina.jpeg",
  },
  {
    id: 32,
    title: "Syracuse",
    country: {name:"Italy", code:"it"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/96100+Syracuse,+Province+of+Syracuse,+Italy/@37.0791437,15.2358769,13z/data=!3m1!4b1!4m5!3m4!1s0x1313ce8da28bdf79:0xd1736683b2c58b87!8m2!3d37.0754739!4d15.2865861",
    startDate: "11 October 2021",
    endDate: "15 October 2021",
    description: "Aperol Spritz. Restaurants. Archimedes",
    imageUrl: "syracuse.jpeg",
    tags: ["Remote Working"],
  },
  {
    id: 33,
    title: "Tenerife",
    country: {name:"Spain", code:"es"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Tenerife/@28.2931088,-16.8028537,10z/data=!3m1!4b1!4m5!3m4!1s0xc4029effe8682ed:0xb01a4bf1c84baf3c!8m2!3d28.2915637!4d-16.6291304",
    startDate: "15 October 2021",
    endDate: "29 October 2021",
    description: "Loro Parque. Swimming. Teide.",
    imageUrl: "tenerife.jpeg",
  },
  {
    id: 34,
    title: "Madrid",
    country: {name:"Spain", code:"es"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Madrid,+Spain/@40.4378698,-3.8196214,11z/data=!3m1!4b1!4m5!3m4!1s0xd422997800a3c81:0xc436dec1618c2269!8m2!3d40.4167754!4d-3.7037902",
    startDate: "29 October 2021",
    endDate: "5 November 2021",
    description: "Tapas. Art. Flamenco. Rioja. Churros.",
    imageUrl: "madrid.jpeg",
    tags: ["Remote Working"],
  },
  {
    id: 35,
    title: "Marrakech",
    country: {name:"Morocco", code:"ma"},
    continent: "Africa",
    googleMapsUrl:
      "https://www.google.com/maps/place/Marrakesh,+Morocco/@31.6346023,-8.0778935,12z/data=!3m1!4b1!4m5!3m4!1s0xdafee8d96179e51:0x5950b6534f87adb8!8m2!3d31.6294723!4d-7.9810845",
    startDate: "5 November 2021",
    endDate: "9 November 2021",
    description: "Ouzoud waterfall. Markets. Tajines",
    imageUrl: "marrakech.jpeg",
  },
  {
    id: 36,
    title: "Sahara Desert",
    country: {name:"Morocco", code:"ma"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Sahara+Desert/@21.9751691,13.0830603,5z/data=!3m1!4b1!4m5!3m4!1s0x13883b64fb267151:0xd6406bdc582d7390!8m2!3d23.4162027!4d25.66283",
    startDate: "9 November 2021",
    endDate: "11 November 2021",
    description: "Camel rides. Sunset sunrise. Movie sets.",
    imageUrl: "sahara.jpeg",
    tags: ["Travel Highlight"],
  },
  {
    id: 37,
    title: "Essoauira",
    country: {name:"Morocco", code:"ma"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Essaouira,+Morocco/@31.5109424,-9.7800518,14z/data=!3m1!4b1!4m5!3m4!1s0xdad9a4e9f588ccf:0x57421a176d5d7d30!8m2!3d31.5084926!4d-9.7595041",
    startDate: "11 November 2021",
    endDate: "14 November 2021",
    description: "Beach. Surfing. Markets.",
    imageUrl: "essoauira.jpeg",
  },
  {
    id: 38,
    title: "Warsaw",
    country: {name:"Poland", code:"pl"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Warsaw,+Poland/@52.2328546,20.9207678,11z/data=!3m1!4b1!4m5!3m4!1s0x471ecc669a869f01:0x72f0be2a88ead3fc!8m2!3d52.2296756!4d21.0122287",
    startDate: "11 February 2022",
    endDate: "13 February 2022",
    description: "Vodka. Coffee. Dumplings.",
    imageUrl: "poland.jpeg",
  },
  {
    id: 39,
    title: "Boston",
    country: {name:"United States", code:"us"},
    continent: "North America",
    googleMapsUrl:
      "https://www.google.com/maps/place/Boston,+MA,+USA/@42.3142643,-71.1107119,11z/data=!3m1!4b1!4m5!3m4!1s0x89e3652d0d3d311b:0x787cbf240162e8a0!8m2!3d42.3600825!4d-71.0588801",
    startDate: "16 March 2022",
    endDate: "20 March 2022",
    description: "Isabella Stewart Gardner. Paddy's Day. Tea Party.",
    imageUrl: "boston.jpeg",
  },
  {
    id: 40,
    title: "Riga",
    country: {name:"Latvia", code:"lv"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Riga,+Latvia/@56.9718172,23.8482035,10z/data=!3m1!4b1!4m5!3m4!1s0x46eecfb0e5073ded:0x400cfcd68f2fe30!8m2!3d56.9676941!4d24.1056221",
    startDate: "1 April 2022",
    endDate: "3 April 2022",
    description: "KGB Museum. Rooftop cocktails. Pub crawl.",
    imageUrl: "riga.jpeg",
  },
  {
    id: 41,
    title: "Budapest (2nd time)",
    country: {name:"Hungary", code:"hu"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Budapest,+Hungary/@47.4813346,18.8494256,10z/data=!3m1!4b1!4m5!3m4!1s0x4741c334d1d4cfc9:0x400c4290c1e1160!8m2!3d47.497912!4d19.040235",
    startDate: "29 April 2022",
    endDate: "2 May 2022",
    description: "Baths. Birthdays. Jacuzzi. Margaret Island.",
    imageUrl: "budapest.jpeg",
  },
  {
    id: 42,
    title: "Brussels",
    country: {name:"Belgium", code:"be"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Brussels,+Belgium/@50.8552113,4.3052053,12z/data=!3m1!4b1!4m5!3m4!1s0x47c3a4ed73c76867:0xc18b3a66787302a7!8m2!3d50.8476424!4d4.3571696",
    startDate: "13 May 2022",
    endDate: "14 May 2022",
    description:
      "Waffles. Atomium - national structure. Dinosaurs - Iguanodon. Chocolate making class.",
    imageUrl: "brussels.jpeg",
    tags: ["Dinosaurs"]
  },
  {
    id: 43,
    title: "Bruges",
    country: {name:"Belgium", code:"be"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Bruges,+Belgium/@51.260914,3.0816799,11z/data=!3m1!4b1!4m5!3m4!1s0x47c350d0c11e420d:0x1aa2f35ac8834df7!8m2!3d51.2091807!4d3.2247552",
    startDate: "15 May 2022",
    endDate: "16 May 2022",
    description: "Beer Factory. In Bruge. Colin Farrell.",
    imageUrl: "bruges.jpeg",
  },
  {
    id: 44,
    title: "Sorrento (2nd time)",
    country: {name:"Italy", code:"it"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/80067+Sorrento,+Metropolitan+City+of+Naples,+Italy/@40.6238474,14.3402576,13z/data=!3m1!4b1!4m5!3m4!1s0x133b99536dbf5b61:0x2644eb57abc0ce40!8m2!3d40.6262925!4d14.3757985",
    startDate: "3 June 2022",
    endDate: "6 June 2022",
    description: "Katherine's hen party. Capri Boat trip. ",
    imageUrl: "sorrento2.jpeg",
  },
  {
    id: 45,
    title: "Paris",
    country: {name:"France", code:"fr"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Paris,+France/@48.8588548,2.347035,11z/data=!3m1!4b1!4m5!3m4!1s0x47e66e1f06e2b70f:0x40b82c3688c9460!8m2!3d48.856614!4d2.3522219",
    startDate: "1 July 2022",
    endDate: "4 July 2022",
    description: "Eiffel Tower. Croissants in bed. Versailles.",
    imageUrl: "paris.jpeg",
  },
  {
    id: 46,
    title: "Bouffémont",
    country: {name:"France", code:"fr"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/95570+Bouff%C3%A9mont,+France/@49.0406923,2.2644919,13z/data=!3m1!4b1!4m5!3m4!1s0x47e642c9a49500c7:0x40b82c3688b3560!8m2!3d49.043675!4d2.307852",
    startDate: "4 July 2022",
    endDate: "10 July 2022",
    description: "Mansion House. Monet Gardens. Champagne.",
    imageUrl: "bouffemont.jpeg",
    tags: ["Amazing Food"],
  },
  {
    id: 47,
    title: "Miami",
    country: {name:"United States", code:"us"},
    continent: "North America",
    googleMapsUrl:
      "https://www.google.com/maps/place/Miami,+FL,+USA/@25.7823842,-80.3120316,12z/data=!3m1!4b1!4m5!3m4!1s0x88d9b0a20ec8c111:0xff96f271ddad4f65!8m2!3d25.7616798!4d-80.1917902",
    startDate: "11 November 2022",
    endDate: "24 November 2022",
    description: "South Beach. Everglades. Little Havana. Carnival Cruise.",
    imageUrl: "miami.jpeg",
  },
  {
    id: 48,
    title: "Nassau",
    country: {name:"Bahamas", code:"bs"},
    continent: "North America",
    googleMapsUrl:
      "https://www.google.com/maps/place/Nassau,+The+Bahamas/@25.0324693,-77.5722475,11z/data=!3m1!4b1!4m5!3m4!1s0x892f7c99b981dbc9:0x2aef01d3485e50d2!8m2!3d25.0443312!4d-77.3503609",
    startDate: "17 November 2022",
    endDate: "17 November 2022",
    description: "Paradise Island. Rum Cake. Pirates. Atlantis.",
    imageUrl: "nassau.jpeg",
  },
  {
    id: 49,
    title: "Santiago",
    country: {name:"Chile", code:"cl"},
    continent: "South America",
    googleMapsUrl:
      "https://www.google.com/maps/place/Santiago,+Santiago+Metropolitan+Region/@-33.4727088,-70.7702594,11z/data=!3m1!4b1!4m6!3m5!1s0x9662c5410425af2f:0x8475d53c400f0931!8m2!3d-33.4488897!4d-70.6692655!16zL20vMGRscXY",
    startDate: "3 February 2023",
    endDate: "9 February 2023",
    description: "Boragó (crazy food restaurant), Rooftop pools, Pisco Sour",
    imageUrl: "santiago.jpg",
    slides: ["santiago-center.jpeg", "Santiago is full of rooftop pools.", "santiago-rooftop-pool.jpeg", 'We visited the "fifth best restaurant" in South America called Borago. The food was a tad crazy :D',"santiago-borago-flower.jpeg"],
  },
  {
    id: 50,
    title: "Valparaíso",
    country: {name:"Chile", code:"cl"},
    continent: "South America",
    googleMapsUrl:
      "https://www.google.com/maps/place/Valpara%C3%ADso/@-33.0501955,-71.6461539,13z/data=!3m1!4b1!4m6!3m5!1s0x9689dde3de20cec7:0xeb0a3a8cbfe19b76!8m2!3d-33.047238!4d-71.6126885!16zL20vMGo4bG4",
    startDate: "10 February 2023",
    endDate: "12 February 2023",
    description: "Street Art, Artistic Steps, Coffee Hostel, Funicular",
    imageUrl: "valparaiso.jpg",
    slides: ["Valparaiso is covered top to bottom in the most amazing street art.", "valparaiso-door.jpeg",  "valparaiso-mask.jpeg", "valparaiso-sea-lion.jpeg", "valparaiso-mario.jpeg", "The town is extremely hilly and has historical funiculars (like cable cars) that take you up and down.", "valparaiso-funicular.jpeg","valparaiso-stairs.jpg", "valparaiso-lion.jpeg","The town also has many sets of stairs that are creatively painted.", "valparaiso-steps-heels.jpeg", "valparaiso-stairs.jpeg"],
  },
  {
    id: 51,
    title: "Viña del Mar",
    country: {name:"Chile", code:"cl"},
    continent: "South America",
    googleMapsUrl:
      "https://www.google.com/maps/place/Vi%C3%B1a+del+Mar,+Valpara%C3%ADso/data=!4m2!3m1!1s0x9689de84ead41255:0x8e5fde76df3d413f?sa=X&ved=2ahUKEwjst_Tftq79AhVUAdQKHW7UCdkQ8gF6BAgIEAI",
    startDate: "12 February 2023",
    endDate: "12 February 2023",
    description: "Beach, Residential, Bus back to Santiago",
    imageUrl: "vinadelmar.jpg",
  },
  {
    id: 52,
    title: "Easter Island",
    country: {name:"Chile", code:"cl"},
    continent: "South America",
    googleMapsUrl:
      "",
    startDate: "13 February 2023",
    endDate: "17 February 2023",
    description: "Most remote Island, Maoi, Statues, Kari Kari, Sunrise, Swimming with turtles, Benjamin",
    imageUrl: "easterisland.jpg",
    slides: ["Easter Island/Rapa Nui is a 5 hour plane trip from Santiago, and is the most remote island in the world.", "The famous monolithic Moai statues were built in a quarry first.","easter-island-quarry.jpeg", "There were then transported to platforms beside the sea where they were painted and positioned facing the land.", "easter-island-maoi-platform.jpeg",
    "easter-island-fifteen.jpeg", "easter-island-sunrise-fifteen.jpg", "easter-island-stones.jpeg", "The people are of polynesian descent and Kari Kari is the local dance culture.", "easter-island-dancing.jpeg",],
    tags: ["Travel Highlight"],
  },
  {
    id: 53,
    title: "Mendoza",
    country: {name:"Argentina", code:"ar"},
    continent: "South America",
    googleMapsUrl:
      "https://www.google.com/maps/place/Mendoza,+Capital+Department,+Mendoza+Province,+Argentina/@-32.8833303,-68.8936244,13z/data=!3m1!4b1!4m15!1m8!3m7!1s0x9679745b5dd5fffd:0x902586f1d047824!2sMendoza+Province,+Argentina!3b1!8m2!3d-34.5869034!4d-68.1431414!16zL20vMDFqaDc2!3m5!1s0x967e093ec45179bf:0x205a78f6d20efa3a!8m2!3d-32.8894587!4d-68.8458386!16zL20vMDJtdHd0",
    startDate: "19 February 2023",
    endDate: "22 February 2023",
    description: "Wine, Malbec, Steak, Bus through the Andes",
    imageUrl: "mendoza.jpg",
    slides: ["mendoza-city.jpeg", "In Mendoza we drank a lot of Malbec and ate a lot of steak.", "mendoza-steak.jpeg", "We also went around the vineyards and an olive oil factory."],
    tags: ["Amazing Food"],
  },
  {
    id: 54,
    title: "San Pedro de Atacama",
    country: {name:"Chile", code:"cl"},
    continent: "South America",
    googleMapsUrl:
      "https://www.google.com/maps/place/San+Pedro+de+Atacama,+Antofagasta,+Chile/@-22.914014,-68.2074194,15z/data=!3m1!4b1!4m6!3m5!1s0x96a84c7aa92abf3f:0x7af4614cacf997d1!8m2!3d-22.9087073!4d-68.1997156!16zL20vMDQ4aHhw",
    startDate: "23 February 2023",
    endDate: "26 February 2023",
    description: "-5 in Geysers, floating in salt lagoons, Luna Valley",
    imageUrl: "sanpedro.jpeg",
    slides: ["san-pedro-desert.jpeg", "San Pedro de Atacama is one of the driest places on earth.", "We visited the geysers here at dawn and it was super cold: -5 degrees celsius in Summer!","san-pedro-geysers.jpeg", "Valle de la Luna, is so called because this place looks like the surface of the moon.", "We also swam in salt lagoons, where you float incredibly easily.", "San Pedro is so high up and there's no clouds so stargazing here is a must!"],
  },
  {
    id: 55,
    title: "Uyuni",
    country: {name:"Bolivia", code:"bo"},
    continent: "South America",
    googleMapsUrl:
      "https://www.google.com/maps/place/Uyuni,+Bolivia/@-20.4607082,-66.8448545,14z/data=!3m1!4b1!4m15!1m8!3m7!1s0x915edf8977bba295:0x1c9ec2bb0115edbf!2sBolivia!3b1!8m2!3d-16.290154!4d-63.588653!16zL20vMDE2NXY!3m5!1s0x93ffbad958d3f2bd:0x1455841e2c4e6653!8m2!3d-20.4603534!4d-66.8260649!16zL20vMDZyYzF3",
    startDate: "27 February 2023",
    endDate: "1 March 2023",
    description: "Salt Flats, Canyons, Red/Green/Black lagoon, Flamingos, Lamas",
    imageUrl: "uyuni.jpg",
    slides: ["uyuni-mountains.jpeg", "uyuni-canyon.jpeg", "uyuni-cactus-cat.jpeg", "uyuni-llamas.jpeg", "Bolivia has red lagoons, green lagoons and black lagoons. The red lagoon is covered in flamingos.","uyuni-red-lagoon.jpeg", "uyuni-geysers.jpeg", "The salt flats give you a fun opportunity to play with perspective.", "uyuni-wine-salt-flats.jpeg", "uyuni-dino.jpeg", "We stayed in a salt hotel. Even the beds were made of salt!", "uyuni-salt-hotel-2.jpeg", "uyuni-salt-hotel.jpeg"],
    tags: ["Travel Highlight"],
  },
  {
    id: 56,
    title: "Sucre",
    country: {name:"Bolivia", code:"bo"},
    continent: "South America",
    googleMapsUrl:
      "https://www.google.com/maps/place/Sucre,+Bolivia/@-19.0206372,-65.3298326,12z/data=!3m1!4b1!4m6!3m5!1s0x93fbc8bc8187832f:0xbd9df259af1bee96!8m2!3d-19.035345!4d-65.2592128!16zL20vMGJ0NmM",
    startDate: "2 March 2023",
    endDate: "5 March 2023",
    description: "Dinosaur Footprints, Hot Chocolate, Dance of the devils",
    imageUrl: "sucre.jpg",
    slides: ["In Sucre, we visited a wall of dinosaur footprints.", "In dinosaur times, this land was horizontal and lead to a lake where dinosaurs gathered to drink.", "sucre-many-footprints.jpeg", "sucre-footprint.jpeg"],
    tags: ["Dinosaurs"]
  },
  {
    id: 57,
    title: "Cartagena",
    country: {name:"Colombia", code:"co"},
    continent: "South America",
    googleMapsUrl:
      "https://www.google.com/maps/place/Cartagena,+Cartagena+Province,+Bolivar/@11.1545732,-74.8630617,6.02z/data=!4m6!3m5!1s0x8ef625e7ae9d1351:0xb161392e033f26ca!8m2!3d10.3932277!4d-75.4832311!16zL20vMGg0NW4",
    startDate: "6 March 2023",
    endDate: "18 March 2023",
    description: "Wedding, Coastal Islands",
    imageUrl: "cartagena.jpg",
    slides: ["cartagena-boating.jpeg"],
    tags: ["Remote Working"],
  },
  {
    id: 58,
    title: "San Carlos",
    country: {name:"Colombia", code:"co"},
    continent: "South America",
    googleMapsUrl:
      "https://www.google.com/maps/place/San+Carlos,+Antioquia/@6.1879248,-74.9981754,16.23z/data=!4m6!3m5!1s0x8e46aa9ee37e7727:0x2e6e3f7d0fe54dc0!8m2!3d6.187979!4d-74.993227!16zL20vMDdrNGs5",
    startDate: "19 March 2023",
    endDate: "2 April 2023",
    description: "Spanish School, Waterfalls, Canyoning, Coffee farm",
    imageUrl: "sancarlos.jpg",
    slides: ["In San Carlos we went to Spanish Adventure (a spanish school and adventure camp) for two weeks.","san-carlos-art.jpeg"],
    tags: ["Remote Working"],
  },
  {
    id: 59,
    title: "Guatape",
    country: {name:"Colombia", code:"co"},
    continent: "South America",
    googleMapsUrl:
      "https://www.google.com/maps/place/Guatap%C3%A9,+Antioquia/@6.2311481,-75.1625134,16z/data=!3m1!4b1!4m6!3m5!1s0x8e441d2972edf1bb:0xc8e9c49c096f7adc!8m2!3d6.2337643!4d-75.1592213!16zL20vMDdodnhq",
    startDate: "2 April 2023",
    endDate: "4 April 2023",
    description: "The Rock, 700 steps, Pablo Escobar's House, cinnamon rolls",
    imageUrl: "guatape.jpg",
    slides: ["In Guatape, we visited Pablo Escobar's summer house","guatape-summer.jpeg", "Guatape is famous for the massive rock that you can climb.", "However, it also has many water sports and the town has really lovely shops and cafes."],
    tags: ["Remote Working"],
  },
  {
    id: 60,
    title: "Medellín",
    country: {name:"Colombia", code:"co"},
    continent: "South America",
    googleMapsUrl:
      "https://www.google.com/maps/place/Medell%C3%ADn,+Medellin,+Antioquia/@6.2442034,-75.5812115,12z/data=!3m1!4b1!4m6!3m5!1s0x8e4428dfb80fad05:0x42137cfcc7b53b56!8m2!3d6.2476376!4d-75.5658153!16zL20vMDF4XzZz",
    startDate: "5 April 2023",
    endDate: "28 April 2023",
    description: "Comuna 13, nightlife, dancing, soccer game",
    slides: ["We visited the Pablo Escobar museum. Colombia's 'He who must not be named' is a controversial character to say the least.", "medellin-pablo-escobar.jpeg", "medellin-football.jpeg"],
    imageUrl: "medellin.jpg",
    tags: ["Remote Working"],
  },
  {
    id: 61,
    title: "Jardín",
    country: {name:"Colombia", code:"co"},
    continent: "South America",
    googleMapsUrl:
      "https://www.google.com/maps/place/Jardin/data=!4m2!3m1!1s0x8e46539d25362181:0xe1e546c17dd13fbc?sa=X&ved=2ahUKEwjFvMG124T_AhUekWoFHVtPAa4Q8gF6BAhmEAI",
    startDate: "21 April 2023",
    endDate: "23 April 2023",
    description: "Hiking to waterfalls, cable car, waterfall of love",
    slides: ["Jardin is about a 4 hour drive from Medellin and is a town full of waterfalls and beautiful birds.", "jardin-birds.jpeg", "jardin-waterfall.jpeg"],
    imageUrl: "jardin.jpeg",
    tags: ["Remote Working"],
  },
  {
    id: 62,
    title: "El Retiro",
    country: {name:"Colombia", code:"co"},
    continent: "South America",
    googleMapsUrl:
      "https://www.google.com/maps/place/Retiro,+Antioquia/@6.0609544,-75.5128822,15z/data=!3m1!4b1!4m6!3m5!1s0x8e469ad88b01875b:0xf6fb1427cd59ffb0!8m2!3d6.060955!4d-75.502582!16zL20vMDdrNGhm",
    startDate: "28 April 2023",
    endDate: "30 April 2023",
    description: "Bubble Sky Glamping, telescope, jacuzzi, bird sanctuary",
    imageUrl: "el-retiro.jpeg",
    slides: ["el-retiro-bubble.jpeg", "Birthdays in style in a bubble in the sky.", "el-retiro-jacuzzi.jpeg", "el-retiro-ruth-in-jacuzzi.jpeg"],
    tags: ["Remote Working"],
  },
  {
    id: 63,
    title: "Santa Marta",
    country: {name:"Colombia", code:"co"},
    continent: "South America",
    googleMapsUrl:
      "https://www.google.com/maps/place/Santa+Marta,+Magdalena/@11.2315224,-74.2235968,13z/data=!3m1!4b1!4m6!3m5!1s0x8ef4f66ff59a173d:0x124b95fc153af9b8!8m2!3d11.2403547!4d-74.2110227!16zL20vMDJuc2xj",
    startDate: "30 April 2023",
    endDate: "4 May 2023",
    description: "Dreamer hostel, pool, sunsets",
    imageUrl: "santamarta.jpeg",
    tags: ["Remote Working"],
  },
  {
    id: 64,
    title: "Parque Tayrona",
    country: {name:"Colombia", code:"co"},
    continent: "South America",
    googleMapsUrl:
      "https://www.google.com/maps/place/Parque+Nacional+Natural+Tayrona/@11.2950666,-74.1422948,11.33z/data=!4m6!3m5!1s0x8ef48b22eaf45f4d:0x485723dccbdb882f!8m2!3d11.3064409!4d-74.0657561!16s%2Fm%2F03c4nqv",
    startDate: "5 May 2023",
    endDate: "7 May 2023",
    description: "Beach hop, monkeys, locusts, iguanas, tawaca",
    imageUrl: "tayrona.jpeg",
    slides: ["We stayed in a hotel called Tawaca just outside the park. It had the most amazing wildlife.","tayrona-lizard.jpeg", "tayrona-locust.jpeg", "tayrona-tortoise.jpeg", "Tayrona Park is a huge jungle/beach paradise.", "tayrona-beach.jpeg"],
    tag: ["Incredible Wildlife"]
  },
  {
    id: 65,
    title: "Taganga",
    country: {name:"Colombia", code:"co"},
    continent: "South America",
    googleMapsUrl:
      "https://www.google.com/maps/place/Taganga,+Santa+Marta,+Magdalena/@11.2681514,-74.1937269,16z/data=!3m1!4b1!4m6!3m5!1s0x8ef4f537886c53c5:0xbba33ac823920161!8m2!3d11.26848!4d-74.1895755!16s%2Fg%2F122891m9",
    startDate: "7 May 2023",
    endDate: "25 May 2023",
    description: "Flamenco co-working, diving, volunteering",
    imageUrl: "taganga.jpeg",
    tags: ["Remote Working"],
  },
  {
    id: 66,
    title: "Bogota",
    country: {name:"Colombia", code:"co"},
    continent: "South America",
    googleMapsUrl: "https://www.google.com/maps/place/Bogot%C3%A1,+Bogota,+Colombia/@4.6482784,-74.2726202,11z/data=!3m1!4b1!4m6!3m5!1s0x8e3f9bfd2da6cb29:0x239d635520a33914!8m2!3d4.7109886!4d-74.072092!16zL20vMDFkenlj?entry=ttu",
    startDate: "26 May 2023",
    endDate: "1 June 2023",
    description: "Botero, el Chato, gold museum",
    slides: ["Botero is a Colombian artist who depicts figures in exaggerated forms.","bogota-botero-mona-lisa.jpeg", "bogota-botero.jpeg", "bogota-cafe.jpeg"],
    imageUrl: "bogota.jpeg",
    tags: ["Remote Working"],
  },
  {
    id: 67,
    title: "Quito",
    country: {name:"Ecuador", code:"ec"},
    continent: "South America",
    googleMapsUrl: "https://www.google.com/maps/place/Quito,+Ecuador/@-0.1865936,-78.5953515,11z/data=!3m1!4b1!4m6!3m5!1s0x91d59a4002427c9f:0x44b991e158ef5572!8m2!3d-0.1806532!4d-78.4678382!16zL20vMDFkdHEx?entry=ttu",
    startDate: "1 June 2023",
    endDate: "3 June 2023",
    description: "Equator line, Community hostel, gold church, chocolate tour",
    slides: ["Here we stood on the equator line and balanced an egg on a nail.", "quito-latitude-0.jpeg", "We had the most amazing brownies and pure cocoa hot chocolates on this chocolate tour.", "quito-chocolate-tour.jpeg"],
    imageUrl: "quito.jpeg",
  },
  {
    id: 68,
    title: "Amazon Rainforest",
    country: {name:"Ecuador", code:"ec"},
    continent: "South America",
    googleMapsUrl: "https://www.google.com/maps/place/Amazon+Rainforest/@-3.4653053,-63.1385942,9z/data=!4m10!1m2!2m1!1samazon+ecuador!3m6!1s0x91e8605342744385:0x3d3c6dc1394a7fc7!8m2!3d-3.4653053!4d-62.2158805!15sCg5hbWF6b24gZWN1YWRvciIDiAEBWhAiDmFtYXpvbiBlY3VhZG9ykgEFd29vZHOaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVTnphalpVYW01blJSQULgAQA!16zL20vMGN4NHA?entry=ttu",
    startDate: "4 June 2023",
    endDate: "7 June 2023",
    description: "Monkeys, caimans, anaconda, yuka bread",
    slides: ["amazon-rainbow.jpeg", "The journey to the Amazon, took an overnight bus, a 4 hour shuttle and a 2 hour canoe trip.", "amazon-beatle.jpeg", "amazon-caimon.jpeg", "amazon-sloth.jpeg", "amazon-stinky-turkey.jpeg"],
    imageUrl: "amazon.jpeg",
    tags: ["Travel Highlight", "Incredible Wildlife"]
  },
  {
    id: 69,
    title: "Banos",
    country: {name:"Ecuador", code:"ec"},
    continent: "South America",
    googleMapsUrl: "https://www.google.com/maps/place/Banos,+Ecuador/@-1.3952545,-78.4256887,15z/data=!3m1!4b1!4m6!3m5!1s0x91d3912260082689:0xdb67c8f2fcd8aee9!8m2!3d-1.3928344!4d-78.4268758!16zL20vMDY5MzN4?entry=ttu",
    startDate: "8 June 2023",
    endDate: "11 June 2023",
    description: "Thermal baths, spa days, chocolate factory",
    slides: ["The Devil's Cauldron waterfall is a must see in Banos.","banos-waterfall.jpeg", "In Banos you can swing off the end of the world.","banos-end-of-the-world.jpeg", "The most amazing hot chocolate-coffee combo was had here.", "banos-hot-chocolate.jpeg", "banos-spa.jpeg"],
    imageUrl: "banos.jpg",
  },
  {
    id: 70,
    title: "Cotopaxi",
    country: {name:"Ecuador", code:"ec"},
    continent: "South America",
    googleMapsUrl: "https://www.google.com/maps/place/Cotopaxi,+Ecuador/@-0.7755448,-79.5198655,9z/data=!3m1!4b1!4m6!3m5!1s0x91d461069d795bd1:0x835305dda9893b50!8m2!3d-0.8384206!4d-78.6662678!16zL20vMDM2NXhr?entry=ttu",
    startDate: "11 June 2023",
    endDate: "14 June 2023",
    description: "Hobbit houses, volcano base camp 4800 meters high, jacuzzi",
    slides: ["cotopaxi-net.jpeg", "We stayed in hobbit houses, built into a hill and looking out on the volcano cotopaxi.", "cotopaxi-hobbit-houses.jpeg", "cotopaxi-volcano.jpeg","We hiked to the base camp of Cotopaxi, at 4800 meters high. It was a little chilly :D","cotopaxi-base-camp.jpeg"],
    imageUrl: "cotopaxi.jpeg",
  },
  {
    id: 71,
    title: "Galapagos Islands",
    country: {name:"Ecuador", code:"ec"},
    continent: "South America",
    googleMapsUrl: "https://www.google.com/maps/place/Gal%C3%A1pagos+Islands/@-0.3830043,-91.7429737,8z/data=!3m1!4b1!4m6!3m5!1s0x9021d9b43bec07cd:0xfb3e68fef05dae23!8m2!3d-0.383106!4d-90.4233344!16zL20vMDE2empq?entry=ttu",
    startDate: "15 June 2023",
    endDate: "24 June 2023",
    description: "Pelicans, marine iguanas, sea turtles, sea otters",
    slides: ["galapagos-pelican.jpeg", "We visited the islands of San Cristobal, Isabella and Santa Cruz.","galapagos-penguin.jpeg","We swam with sea lions and snorkled with sea turtles.","galapagos-sea-lion.jpeg", "galapagos-sea-turtle.jpeg", "galapagos-flamingo.jpeg", "At kicker rock you can dive with sharks.",
    "galapagos-kicker-rock.jpeg", "galapagos-giant-tortoise.jpeg", "galapagos-booby.jpeg", "galapagos-marine-iguanas.jpeg", "galapagos-lake.jpeg"],
    imageUrl: "galapagos.jpeg",
    tags: ["Incredible Wildlife", "Travel Highlight"],
  },
  {
    id: 72,
    title: "Clare",
    country: {name:"Ireland", code:"ie"},
    continent: "Europe",
    googleMapsUrl:
      "https://www.google.com/maps/place/Clare,+Co.+Clare,+Ireland/@52.8598167,-9.7698974,9z/data=!3m1!4b1!4m6!3m5!1s0x485b11f9c88251e3:0x400c7a72e2a7680!8m2!3d52.904532!4d-8.9811351!16zL20vMG1fY2c?entry=ttu",
    startDate: "7 August 2023",
    endDate: "13 August 2023",
    description: "Quin Abbey, Family, Morans, Seafood, Shopping",
    imageUrl: "clare.jpeg",
    tags: ["Home Country", "Remote Working"],
  },
  {
    id: 73,
    title: "Cambridge",
    country: {name:"England", code:"gb-eng"},
    continent: "Europe",
    googleMapsUrl: "https://www.google.com/maps/place/Charlotte,+NC/data=!4m2!3m1!1s0x88541fc4fc381a81:0x884650e6bf43d164?sa=X&ved=2ahUKEwjNptjvzPuBAxX9FFkFHbBTC5oQ8gF6BAgKEAA&ved=2ahUKEwjNptjvzPuBAxX9FFkFHbBTC5oQ8gF6BAgMEAI",
    startDate: "8 September 2023",
    endDate: "11 September 2023",
    description: "Punting, Clara's Birthday, Pims",
    imageUrl: "cambridge.jpeg",
  },
  {
    id: 74,
    title: "Charlotte",
    country: {name:"United States", code:"us"},
    continent: "North America",
    googleMapsUrl: "https://www.google.com/maps/place/Charlotte,+NC/data=!4m2!3m1!1s0x88541fc4fc381a81:0x884650e6bf43d164?sa=X&ved=2ahUKEwjNptjvzPuBAxX9FFkFHbBTC5oQ8gF6BAgKEAA&ved=2ahUKEwjNptjvzPuBAxX9FFkFHbBTC5oQ8gF6BAgMEAI",
    startDate: "13 October 2023",
    endDate: "18 October 2023",
    description: "Skyscrapers, Shopping, Fried Chicken, Grace's Birthday",
    imageUrl: "charlotte.jpeg",
  },
  {
    id: 75,
    title: "Charleston",
    country: {name:"United States", code:"us"},
    continent: "North America",
    googleMapsUrl: "https://www.google.com/maps/place/Charlotte,+NC/data=!4m2!3m1!1s0x88541fc4fc381a81:0x884650e6bf43d164?sa=X&ved=2ahUKEwjNptjvzPuBAxX9FFkFHbBTC5oQ8gF6BAgKEAA&ved=2ahUKEwjNptjvzPuBAxX9FFkFHbBTC5oQ8gF6BAgMEAI",
    startDate: "18 October 2023",
    endDate: "22 October 2023",
    description: "Shem Creek, Plantations, Oysters, Aligators",
    imageUrl: "shemcreek.jpg",
    tags: ["Amazing Food"],
  },
  {
    id: 76,
    title: "Sydney",
    country: {name:"Australia", code:"au"},
    continent: "Australia",
    googleMapsUrl: "https://www.google.com/maps/place/Sydney+NSW/@-33.8478053,150.6023256,10z/data=!3m1!4b1!4m6!3m5!1s0x6b129838f39a743f:0x3017d681632a850!8m2!3d-33.8688197!4d151.2092955!16zL20vMDZ5NTc?entry=ttu",
    startDate: "24 October 2023",
    endDate: "",
    description: "Flat white, Koalas, Kangaroos, Humpback Whales.",
    imageUrl: "sydney.jpeg",
    tags: ["Current Location", "Place of Residence"],
  },
  {
    id: 77,
    title: "Newcastle",
    country: {name:"Australia", code:"au"},
    continent: "Australia",
    googleMapsUrl: "https://www.google.com/maps/place/newscastle+australia/data=!4m2!3m1!1s0x6b733e1371c702e5:0x4017d681632a8a0?sa=X&ved=2ahUKEwi8vJ3y79uDAxVNlK8BHZlCDRgQh8EJegQIDBAA",
    startDate: "16 December 2023",
    endDate: "19 December 2023",
    description: "Beach, Ocean baths, Barramundi",
    imageUrl: "newcastle.jpeg",
  },
  {
    id: 78,
    title: "The Entrance",
    country: {name:"Australia", code:"au"},
    continent: "Australia",
    googleMapsUrl: "https://www.google.com/maps/place/The+Entrance+NSW+2261/@-33.3438662,151.4860075,15z/data=!3m1!4b1!4m6!3m5!1s0x6b72cf7435f13c0d:0x5017d681632de90!8m2!3d-33.3448256!4d151.4932299!16zL20vMDdobDNm?entry=ttu",
    startDate: "4 January 2024",
    endDate: "5 January 2024",
    description: "Pelicans",
    imageUrl: "theentrance.jpeg",
  },
  {
    id: 79,
    title: "Singapore",
    country: {name:"Singapore", code:"sg"},
    continent: "Asia",
    googleMapsUrl: "https://www.google.com/maps/place/Singapore/@1.314,103.84425,11z/data=!3m1!4b1!4m6!3m5!1s0x31da11238a8b9375:0x887869cf52abf5c4!8m2!3d1.352083!4d103.819836!16zL20vMDZ0MnQ?entry=ttu",
    startDate: "20 February 2024",
    endDate: "25 February 2024",
    description: "Marina Bay Sands, Cloud Mountain, Flywheel Event",
    imageUrl: "singapore.jpeg",
  },
  {
    id: 80,
    title: "Byron Bay",
    country: {name:"Australia", code:"au"},
    continent: "Australia",
    googleMapsUrl: "https://www.google.com.au/maps/place/Byron+Bay+NSW+2481/@-28.646862,153.5616139,13z/data=!3m1!4b1!4m6!3m5!1s0x6b9062852d3e762b:0x40609b49043f2d0!8m2!3d-28.6419122!4d153.6105168!16zL20vMDJ0ZzR3?entry=ttu",
    startDate: "28 April 2024",
    endDate: "1st May 2024",
    description: "Birthday Bants, Day Drinking, Lighthouse, Beach, Koalas, Kangaroos, Flying Foxes",
    imageUrl: "byronbay.jpeg",
  },
  {
    id: 81,
    title: "Noosa",
    country: {name:"Australia", code:"au"},
    continent: "Australia",
    googleMapsUrl: "https://www.google.com.au/maps/place/Noosa,+QLD/@-26.3181681,152.776309,11z/data=!3m1!4b1!4m6!3m5!1s0x6b93675fab6b5255:0x1a8f53a4865a8231!8m2!3d-26.3645433!4d152.9676695!16zL20vMDVfbmwz?entry=ttu",
    startDate: "19 June 2024",
    endDate: "21 June 2024",
    description: "Beach, Oysers Happy Hour, Walks, Shopping",
    imageUrl: "noosa.jpeg",
  },
  {
    id: 82,
    title: "K'gari",
    country: {name:"Australia", code:"au"},
    continent: "Australia",
    googleMapsUrl: "https://www.google.com.au/maps/place/K'gari/@-25.2419718,151.9040567,9z/data=!3m1!4b1!4m6!3m5!1s0x6bec611ec3c665f1:0x958afa2ce4c59bca!8m2!3d-25.2398229!4d153.1325243!16zL20vMDFneDI2?entry=ttu",
    startDate: "21 June 2024",
    endDate: "24 June 2024",
    description: "Dingos, Lake McKenzie, Champagne Pools, Sand Dunes, Rainforest",
    imageUrl: "kgari.jpeg",
  },
  {
    id: 83,
    title: "Cairns",
    country: {name:"Australia", code:"au"},
    continent: "Australia",
    googleMapsUrl: "https://www.google.com.au/maps/place/Cairns+QLD/@-16.8804603,145.5517887,11z/data=!3m1!4b1!4m6!3m5!1s0x697862555ba22413:0x400eef17f207860!8m2!3d-16.9203476!4d145.7709529!16zL20vMDFzZ21k?entry=ttu",
    startDate: "24 June 2024",
    endDate: "29 June 2024",
    description: "Hot Air Balloon, Great Barrier Reef, Baron Falls, Skyrail, Kuranda Scenic Railway",
    imageUrl: "cairns.jpeg",
    slides: ["cairns-400-tree.jpg", "cairns-balloon-inside.jpg", "cairns-balloon-sunset.jpg", "cairns-kookaburra.jpg", "cairns-parrot.jpg", "cairns-skyrail.jpg", "cairns-butterfly.jpg"],
  },
  {
    id: 84,
    title: "Darwin",
    country: {name:"Australia", code:"au"},
    continent: "Australia",
    googleMapsUrl: "https://www.google.com.au/maps/place/Darwin+NT/@-12.4257226,130.6217954,11z/data=!3m1!4b1!4m6!3m5!1s0x2cc0a0fc9f59043f:0x30217a82a247c20!8m2!3d-12.4637333!4d130.8444446!16zL20vMDJibTg?entry=ttu",
    startDate: "29 June 2024",
    endDate: "1 July 2024",
    description: "Saltwater Crocodiles, Crocosaurus Cove, Jumping Crocodiles, Mindil Beach Sunset Market, Deckchair Cinema",
    imageUrl: "darwin.jpeg",
    tags: ["Incredible Wildlife"],
    slides: ["darwin-crocodile.jpg", "darwin-crocodile-1.jpg", "darwin-crocodile-2.jpg", "darwin-eagles.jpg", "darwin-museum.jpg", "darwin-cinema-sunset.jpg", "darwin-sunset.jpg", "darwin-weather.jpg"],
  },
  {
    id: 85,
    title: "Uluru",
    country: {name:"Australia", code:"au"},
    continent: "Australia",
    googleMapsUrl: "https://www.google.com.au/maps/place/Uluru/@-25.3456569,131.0268246,15z/data=!3m1!4b1!4m6!3m5!1s0x2b236c2b6d625223:0x43a8cd4d9bc55f21!8m2!3d-25.3444277!4d131.0368822!16zL20vMF81eGM?entry=ttu",
    startDate: "3 July 2024",
    endDate: "7 July 2024",
    description: "Uluru, Kata Tjuta, Sunset, Valley of the Winds, Sounds of Silence, Camels, Stargazing",
    imageUrl: "uluru.jpeg",
    tags: ["Travel Highlight"],
    slides: ["The perimeter of Uluru is an 11 kilometer walk. You see some amazing waterholes, cave art and rock features.", "uluru-basewalk-1.jpg", "uluru-basewalk-2.jpg", "uluru-basewalk-3.jpg", "uluru-basewalk-4.jpg", "The amazing Kata Tjuta and Valley of the Winds.", "uluru-katatjuta-1.jpg", "Stargazing in Central Australia is a wow moment. You can see the milkyway.", "uluru-stargazing.jpg", "Some fabulous sunsets can be seen across the Outback", "uluru-sunset.jpg"],
  },
  {
    id: 86,
    title: "Tasmania",
    country: {name:"Australia", code:"au"},
    continent: "Australia",
    googleMapsUrl: "https://www.google.com/maps/place/Tasmania/@-41.4330464,143.515301,7z/data=!3m1!4b1!4m6!3m5!1s0xaa7aed277e34facf:0x2a8fa5dd29404064!8m2!3d-42.0409059!4d146.8087323!16zL20vMDdjZng?entry=ttu&g_ep=EgoyMDI1MDEyOS4xIKXMDSoASAFQAw%3D%3D",
    startDate: "10 November 2024",
    endDate: "15 November 2024",
    description: "Mona museum, Port Arthur Historic Site, Botanic Gardens, Bruny Island, White Wallabies",
    imageUrl: "tasmania.jpg",
  },
  {
    id: 87,
    title: "Auckland",
    country: {name:"New Zealand", code:"nz"},
    continent: "Australia",
    googleMapsUrl: "https://www.google.com/maps/place/Auckland,+New+Zealand/@-36.8588062,174.3764721,10z/data=!3m1!4b1!4m6!3m5!1s0x6d0d47fb5a9ce6fb:0x500ef6143a29917!8m2!3d-36.8508827!4d174.7644881!16zL20vMDEydHM?entry=ttu&g_ep=EgoyMDI1MDIwMy4wIKXMDSoASAFQAw%3D%3D",
    startDate: "15 November 2024",
    endDate: "17 November 2024",
    description: "Rangitoto Island, Sky Tower, Start of the roadtrip",
    imageUrl: "auckland.jpg",
  },
  {
    id: 88,
    title: "Waitomo",
    country: {name:"New Zealand", code:"nz"},
    continent: "Australia",
    googleMapsUrl: "https://www.google.com/maps/place/Waitomo+Caves,+New+Zealand/@-38.2851776,174.9121579,10.52z/data=!4m6!3m5!1s0x6d6cb7b215429a03:0x500ef6143a316b0!8m2!3d-38.2606874!4d175.1113208!16zL20vMDNqMF8x?entry=ttu&g_ep=EgoyMDI1MDIwMy4wIKXMDSoASAFQAw%3D%3D",
    startDate: "17 November 2024",
    endDate: "18 November 2024",
    description: "Kiwi House, Waitomo Caves, Glowworms, Mangapohue Natural Bridge",
    imageUrl: "waitomo.jpg",
  },
  {
    id: 89,
    title: "Matamata",
    country: {name:"New Zealand", code:"nz"},
    continent: "Australia",
    googleMapsUrl: "https://www.google.com/maps/place/Matamata,+New+Zealand/@-37.8432637,175.4422014,11z/data=!3m1!4b1!4m6!3m5!1s0x6d6c4e27e17a16a9:0x500ef6143a2e1b0!8m2!3d-37.8084687!4d175.7709978!16zL20vMDFwdm42?entry=ttu&g_ep=EgoyMDI1MDIwMy4wIKXMDSoASAFQAw%3D%3D",
    startDate: "18 November 2024",
    endDate: "18 November 2024",
    description: "Hobbiton!!",
    imageUrl: "matamata.jpg",
    tags: ["Travel Highlight"],
  },
  {
    id: 90,
    title: "Rotorua",
    country: {name:"New Zealand", code:"nz"},
    continent: "Australia",
    googleMapsUrl: "https://www.google.com/maps/place/Rotorua,+New+Zealand/@-38.1333969,176.129156,11z/data=!3m1!4b1!4m6!3m5!1s0x6d6e983d82f6b22b:0x500ef6143a39931!8m2!3d-38.1445987!4d176.2377669!16zL20vMDF3eDc0?entry=ttu&g_ep=EgoyMDI1MDIwMy4wIKXMDSoASAFQAw%3D%3D",
    startDate: "18 November 2024",
    endDate: "21 November 2024",
    description: "Waiotapu Thermal Wonderland, Green Lake, Maori Village, Huka Falls, Taupo, Hot tubs, thermal springs",
    imageUrl: "rotorua.jpg",
  },
  {
    id: 91,
    title: "Tongariro",
    country: {name:"New Zealand", code:"nz"},
    continent: "Australia",
    googleMapsUrl: "https://www.google.com/maps/place/Tongariro+National+Park/@-39.1928127,175.5912368,17z/data=!3m1!4b1!4m6!3m5!1s0x6d6b001d8474a167:0xf00ef62249d5650!8m2!3d-39.1928169!4d175.5938117!16zL20vMDM1Z2Qz?entry=ttu&g_ep=EgoyMDI1MDIwMy4wIKXMDSoASAFQAw%3D%3D",
    startDate: "21 November 2024",
    endDate: "22 November 2024",
    description: "National Park, Gollum's Pool, Mount Doom, Taranaki Falls, Ski hotel (skotel)",
    imageUrl: "tongariro.jpg",
  },
  {
    id: 92,
    title: "Whanganui",
    country: {name:"New Zealand", code:"nz"},
    continent: "Australia",
    googleMapsUrl: "https://www.google.com/maps/place/Whanganui+4500,+New+Zealand/@-39.9258816,175.0091268,14z/data=!3m1!4b1!4m6!3m5!1s0x6d6ab8747e76634b:0x500ef6143a39932!8m2!3d-39.9295619!4d175.0467279!16zL20vMDF6OXox?entry=ttu&g_ep=EgoyMDI1MDIwMy4wIKXMDSoASAFQAw%3D%3D",
    startDate: "22 November 2024",
    endDate: "23 November 2024",
    description: "Dinosaur House, Sunset, Beaches, Markets, Viewpoints, Bulls",
    imageUrl: "whanganui.jpg",
    tags: ["Dinosaurs"],
  },
  {
    id: 93,
    title: "Wellington",
    country: {name:"New Zealand", code:"nz"},
    continent: "Australia",
    googleMapsUrl: "https://www.google.com/maps/place/Wellington,+New+Zealand/@-41.2525845,174.589449,11z/data=!3m1!4b1!4m6!3m5!1s0x6d38b1fc49e974cb:0xa00ef63a213b470!8m2!3d-41.2923814!4d174.7787463!16zL20vMDg1M2c?entry=ttu&g_ep=EgoyMDI1MDIwMy4wIKXMDSoASAFQAw%3D%3D",
    startDate: "23 November 2024",
    endDate: "25 November 2024",
    description: "Weta Workshop, Maoi Head, Cable Car, Te Papa Museum, Mount Victoria",
    imageUrl: "wellington.jpg",
  },
  {
    id: 94,
    title: "Melbourne",
    country: {name:"Australia", code:"au"},
    continent: "Australia",
    googleMapsUrl: "https://www.google.com/maps/place/Melbourne+VIC/@-37.9707183,144.392346,9z/data=!3m1!4b1!4m6!3m5!1s0x6ad646b5d2ba4df7:0x4045675218ccd90!8m2!3d-37.8136276!4d144.9630576!16zL20vMGNoZ3pt?entry=ttu&g_ep=EgoyMDI1MDIxOS4xIKXMDSoASAFQAw%3D%3D",
    startDate: "13 February 2025",
    endDate: "16 February 2025",
    description: "Philip Island, Rainbows, Penguins, Vineyard, Brighton Beachhouses, Melbourne Museum Triceratops",
    imageUrl: "melbourne.jpg",
  }
];

export default travelJSONData;
